var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "asset-projects-page"
    }
  }, [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-tasks"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function fn() {
        return [_vm._v("A list of projects related to this asset")];
      },
      proxy: true
    }])
  }, [[_vm._v(" Projects ")]], 2), _c('div', {
    staticClass: "row items-push mb-0"
  }, [_c('div', {
    staticClass: "col-sm-4 col-xl-4"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_vm._m(0), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search,
      expression: "search"
    }],
    staticClass: "form-control border-left-0",
    attrs: {
      "id": "projects-search",
      "type": "text",
      "name": "projects-search",
      "placeholder": "Search Projects.."
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.search = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "col-sm-4 col-xl-3"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter,
      expression: "filter"
    }],
    staticClass: "custom-select",
    attrs: {
      "id": "projects-filter",
      "name": "projects-filter"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.filter = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("All (" + _vm._s(_vm.projects.length) + ")")]), _c('option', {
    attrs: {
      "value": "active"
    }
  }, [_vm._v("Active (" + _vm._s(_vm.projects.filter(function (p) {
    return p.status === 'active';
  }).length) + ")")]), _c('option', {
    attrs: {
      "value": "pending"
    }
  }, [_vm._v("Pending (" + _vm._s(_vm.projects.filter(function (p) {
    return p.status === 'pending';
  }).length) + ")")]), _c('option', {
    attrs: {
      "value": "completed"
    }
  }, [_vm._v("Completed (" + _vm._s(_vm.projects.filter(function (p) {
    return p.status === 'completed';
  }).length) + ")")])])]), _c('div', {
    staticClass: "col-sm-4 col-xl-5 text-right"
  }, [_vm.$permissions.write('asset', _vm.asset) ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "data-cy": "new-project-btn",
      "to": {
        name: 'asset-projects-create'
      }
    }
  }, [_vm._v("New Project")]) : _vm._e()], 1)]), _vm.filteredProjects.length ? _c('div', {
    staticClass: "row row-deck"
  }, _vm._l(_vm.filteredProjects, function (project) {
    return _c('div', {
      key: project._id,
      staticClass: "col-md-6 col-xl-4"
    }, [_c('div', {
      staticClass: "block block-rounded block-border"
    }, [_c('div', {
      staticClass: "block-header"
    }, [_c('div', {
      staticClass: "flex-fill font-size-sm font-w600",
      class: {
        'text-muted': new Date(project.endDate) > Date.now(),
        'text-warning': new Date(project.endDate) < Date.now()
      }
    }, [_c('i', {
      staticClass: "fa fa-clock mr-1"
    }), _vm._v(" " + _vm._s(_vm._f("date")(project.endDate ? project.endDate : 'No date set', 'Do MMM YYYY')) + " ")]), _c('div', {
      staticClass: "block-options"
    }, [_c('div', {
      staticClass: "dropdown"
    }, [_vm.$permissions.write('asset', _vm.asset) ? _c('button', {
      staticClass: "btn-block-option",
      attrs: {
        "type": "button",
        "data-toggle": "dropdown",
        "aria-haspopup": "true",
        "aria-expanded": "false"
      }
    }, [_c('i', {
      staticClass: "fa fa-ellipsis-v"
    })]) : _vm._e(), _c('div', {
      staticClass: "dropdown-menu dropdown-menu-right"
    }, [_vm.$permissions.write('asset', _vm.asset) ? _c('router-link', {
      staticClass: "dropdown-item",
      attrs: {
        "to": {
          name: 'asset-projects-edit',
          params: {
            projectId: project._id
          }
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-pencil-alt mr-1"
    }), _vm._v(" Edit Project ")]) : _vm._e(), _c('div', {
      staticClass: "dropdown-divider",
      attrs: {
        "role": "separator"
      }
    }), _c('a', {
      staticClass: "dropdown-item text-danger",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return function () {
            _vm.modals.remove = project;
          }($event);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-trash mr-1"
    }), _vm._v(" Delete Project ")])], 1)])])]), _c('div', {
      staticClass: "block-content bg-body-light text-center px-4"
    }, [_c('h3', {
      staticClass: "font-size-h4 font-w700 mb-1"
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: 'asset-projects-view',
          params: {
            projectId: project._id
          }
        }
      }
    }, [_vm._v(_vm._s(project.name))])], 1), _c('h4', {
      staticClass: "font-size-h6 text-muted mb-3"
    }, [_vm._v(_vm._s(project.description))]), _c('div', {
      staticClass: "progress push",
      staticStyle: {
        "height": "10px"
      }
    }, [_c('div', {
      staticClass: "progress-bar",
      style: {
        width: "".concat(project.progress, "%")
      }
    })]), _c('div', {
      staticClass: "push"
    }, [_c('span', {
      staticClass: "badge text-uppercase font-w700 py-2 px-3",
      class: {
        'badge-success': project.status === 'completed',
        'badge-warning': project.status === 'active',
        'badge-secondary': project.status === 'pending'
      }
    }, [_vm._v(_vm._s(project.status))])])]), _c('div', {
      staticClass: "block-content block-content-full"
    }, [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-md"
    }, [_c('router-link', {
      staticClass: "btn btn-block btn-primary",
      attrs: {
        "data-cy": project._id + '-view',
        "to": {
          name: 'asset-projects-view',
          params: {
            projectId: project._id
          }
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-search mr-1 opacity-50"
    }), _vm._v(" View ")])], 1), _vm.$permissions.write('asset', _vm.asset) ? _c('div', {
      staticClass: "col-md"
    }, [_c('router-link', {
      staticClass: "btn btn-block btn-alt-secondary",
      attrs: {
        "data-cy": project._id + '-edit',
        "to": {
          name: 'asset-projects-edit',
          params: {
            projectId: project._id
          }
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-pencil mr-1 opacity-50"
    }), _vm._v(" Edit ")])], 1) : _vm._e()])])])]);
  }), 0) : _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("No projects found with the selected filters.")]), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.remove,
      "title": "Delete Project",
      "text": "Please confirm you would like to remove project: <strong>".concat(_vm.modals.remove.name, "</strong>")
    },
    on: {
      "close": function close() {
        _vm.modals.remove = false;
      },
      "submit": _vm.onRemove
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text bg-white"
  }, [_c('i', {
    staticClass: "fa fa-search"
  })])]);
}]

export { render, staticRenderFns }